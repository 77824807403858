<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="connect"
            >Add New Connectors</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>Name</th>
          <th>Type</th>
          <th>Reference ID</th>
          <th></th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.type }}
          </td>

          <td class="budget">
            {{ row.refId }}
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#">Edit</a>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="deleteConnector(row.connectorId)"
                  >Delete</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
      <!--- was outside -->
      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <base-pagination :total="30"></base-pagination>
      </div>
      <!-- end of was outside -->
    </div>
  </div>
</template>
<script>
import connector from "../../api/connector";
import makeAjacentApi from "../../api/ajacent";
import settings from "../../store/settings";

function mapConnectors(connectors) {
  return connectors.map((item) => ({
    name: item.name,
    connectorId: item.connectorId,
    refId: item.refId,
    type: "facebook",
  }));
}

export default {
  name: "connectors-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    window.addEventListener("message", this.onMessage, false);
    this.refreshConnectors();
  },
  destroyed() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    ajacentApi() {
      const jwt = settings.getJwt();
      const organizationId = settings.getOrganizationId();

      const ajacent = makeAjacentApi({ jwt, organizationId });

      return ajacent;
    },
    connect() {
      return connector.connectFacebook();
    },
    refreshConnectors() {
      const ajacent = this.ajacentApi();

      return ajacent.findConnectors().then((payload) => {
        const { data } = payload;
        this.tableData = mapConnectors(data);
      });
    },
    deleteConnector(connectorId) {
      const ajacent = this.ajacentApi();
      return ajacent.deleteConnector(connectorId).then(() => {
        return this.refreshConnectors();
      });
    },
    onMessage(evt) {
      const callbackUri = process.env.VUE_APP_FACEBOOK_CALLBACK;

      if (!callbackUri.startsWith(evt.origin)) {
        console.log("wrong origin");
        return;
      }

      const token = evt.data;

      connector
        .facebookGetMe(token)
        .then((data) => {
          const ajacent = this.ajacentApi();
          const connectorPromises = data.data.map((item) => {
            const connector = {
              name: `${item.name} (${item.category})`,
              refId: item.id,
              accessToken: item.access_token,
              type: "facebook",
            };

            return ajacent.addConnector(connector);
          });

          return Promise.all(connectorPromises);
        })
        .then(() => {
          return this.refreshConnectors();
        });
    },
  },
};
</script>
<style>
</style>
