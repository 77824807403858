<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
   
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col">
          <connectors-table type="dark" title="Connectors"></connectors-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConnectorsTable from "./Tables/ConnectorsTable";

export default {
  name: "connectors",
  components: {
    ConnectorsTable,
  },
};
</script>
<style></style>
