function connectFacebook() {
  const clientId = process.env.VUE_APP_FACEBOOK_CLIENT_ID;
  const callback = process.env.VUE_APP_FACEBOOK_CALLBACK;
  const scopes = 'pages_show_list,pages_read_engagement,pages_manage_metadata,pages_manage_posts,public_profile,pages_read_user_content';

  const url = `https://www.facebook.com:443/v9.0/dialog/oauth
?client_id=${clientId}
&redirect_uri=${decodeURIComponent(callback)}
&scope=${decodeURIComponent(scopes)}`;

  const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=800,left=100,top=100`;

  window.open(url, "connects facebook", params);
}

function facebookGetMe(token) {
  const url = `https://graph.facebook.com/v9.0/me/accounts?access_token=${token}`;

  return fetch(url)
    .then((resp) => resp.json());
}

export default {
  connectFacebook,
  facebookGetMe,
};
