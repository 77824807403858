<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col">
          <members-table type="dark" title="Members"></members-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MembersTable from "./Tables/MembersTable";

export default {
  name: "members",
  components: {
    MembersTable,
  },
};
</script>
<style></style>
