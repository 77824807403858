<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <base-alert type="danger" v-show="errorMessage">
        <strong>Error!</strong> {{ errorMessage }}
      </base-alert>

      <div class="card bg-secondary shadow border-0">
        <!--
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign up with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div>
        -->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign up with credentials</small>
          </div>
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Name"
              addon-left-icon="ni ni-hat-3"
              v-model="model.name"
            >
            </base-input>

            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <div class="text-muted font-italic">
              <small
                >password strength:
                <span class="text-success font-weight-700">strong</span></small
              >
            </div>

            <div class="row my-4">
              <div class="col-12">
                <base-checkbox class="custom-control-alternative">
                  <span class="text-muted"
                    >I agree with the <a href="#!">Privacy Policy</a></span
                  >
                </base-checkbox>
              </div>
            </div>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="register"
                >Create account</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!--
          <a href="#" class="text-light">
            <small>Forgot password?</small>
          </a>
          -->
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import makeApi from "../api/ajacent";
// import settings from "../store/settings";

export default {
  name: "register",
  data() {
    return {
      errorMessage: "",
      model: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    register() {
      const _data = this.model;
      makeApi()
        .register(_data)
        .then(() => {
          this.$router.push("login");
        })
        .catch((err) => {
          this.model.name = "";
          this.model.email = "";
          this.model.password = "";
          this.errorMessage = err.message;
        });
    },
  },
};
</script>
<style>
</style>
