<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total posts"
            type="gradient-red"
            :sub-title="report.totalPostCount.toString()"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <!--
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    -->
          </stats-card>
        </div>

        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total feeds"
            type="gradient-orange"
            :sub-title="report.totalFeedCount.toString()"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <!--
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                        -->
          </stats-card>
        </div>

        <!--
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Sales"
                                type="gradient-green"
                                sub-title="924"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Performance"
                                type="gradient-info"
                                sub-title="49,65%"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                --->
        <card header-classes="bg-transparent">
          <div slot="header" class="row align-items-center">
            <div class="col">
              <h6 class="text-uppercase text-muted ls-1 mb-1">Monthly Post</h6>
              <h5 class="h3 mb-0">Total Post</h5>
            </div>
          </div>

          <bar-chart
            :height="350"
            ref="barChart"
            :chart-data="redBarChart.chartData"
          >
          </bar-chart>
        </card>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <!--
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Sales value</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a
                      class="nav-link py-2 px-3"
                      href="#"
                      :class="{ active: bigLineChart.activeIndex === 0 }"
                      @click.prevent="initBigChart(0)"
                    >
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link py-2 px-3"
                      href="#"
                      :class="{ active: bigLineChart.activeIndex === 1 }"
                      @click.prevent="initBigChart(1)"
                    >
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </card>
        </div>
        -->

        <div class="col-xl-4">
          <!--
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">
                  Monthly Post
                </h6>
                <h5 class="h3 mb-0">Total Post</h5>
              </div>
            </div>

            <bar-chart
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
          --->
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <!--
      <div class="row mt-5">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div>
      -->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
// import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

// Tables
// import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
// import PageVisitsTable from "./Dashboard/PageVisitsTable";

import makeAjacentApi from "../api/ajacent";
import settings from "../store/settings";

export default {
  components: {
    // LineChart,
    BarChart,
    // PageVisitsTable,
    // SocialTrafficTable,
  },
  data() {
    return {
      report: {
        totalPostCount: 0,
        totalFeedCount: 0,
        postMonthlyCount: [],
      },
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Post",
              data: [],
            },
          ],
        },
      },
    };
  },
  methods: {
    ajacentApi() {
      const jwt = settings.getJwt();
      const organizationId = settings.getOrganizationId();

      const ajacent = makeAjacentApi({ jwt, organizationId });

      return ajacent;
    },
    initReport() {
      const api = this.ajacentApi();

      return api.findReports().then((res) => {
        const { data } = res;

        this.report = data[0];
        this.initReportChart();
      });
    },
    initReportChart() {
      const monthLabels = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const { postMonthlyCount } = this.report;

      const labels = postMonthlyCount.map((data) => {
        const { date: val } = data;
        const i = val.indexOf("/");
        if (i === -1) {
          return undefined;
        }
        const v = parseInt(val.substring(i + 1)) - 1;
        const l = monthLabels[v];

        return l;
      });
      const data = postMonthlyCount.map((data) => {
        const { count } = data;
        return count;
      });

      const chartData = {
        labels,
        datasets: [
          {
            label: "Post",
            data,
          },
        ],
      };

      this.redBarChart.chartData = chartData;
    },
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {
    this.initReport();
  },
};
</script>
<style></style>
