<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <base-alert type="danger" v-show="errorMessage">
        <strong>Error!</strong> {{ errorMessage }}
      </base-alert>

      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in with</small>
          </div>

          <b-tabs content-class="mt-3">
            <b-tab title="Login" active>
              <form role="form">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Email"
                  addon-left-icon="ni ni-email-83"
                  v-model="model.email"
                >
                </base-input>

                <base-input
                  class="input-group-alternative"
                  placeholder="Password"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="model.password"
                >
                </base-input>

                <!--
            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            -->
                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="login"
                    >Sign in</base-button
                  >
                </div>
              </form>
            </b-tab>

            <b-tab title="Confirm">
              <form role="form">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Email"
                  addon-left-icon="ni ni-email-83"
                  v-model="model.email"
                >
                </base-input>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Conform Code"
                  addon-left-icon="ni"
                  v-model="model.confirmCode"
                >
                </base-input>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Name"
                  addon-left-icon="ni"
                  v-show="requireRegister"
                  v-model="model.name"
                >
                </base-input>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-show="requireRegister"
                  v-model="model.password"
                >
                </base-input>

                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="confirm"
                    >Confirm</base-button
                  >
                </div>
              </form>
            </b-tab>
          </b-tabs>

          <b-overlay
            :show="isBusy"
            spinner-variant="dark"
            spinner-type="grow"
            spinner-small
            rounded="sm"
            no-wrap
          >
          </b-overlay>
        </div>
        <!--
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div>
        </div>
        -->
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!--
          <a href="#" class="text-light"><small>Forgot password?</small></a>
          -->
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import makeApi from "../api/ajacent";
import settings from "../store/settings";

function errorObject(error) {
  try {
    const json = JSON.parse(error.message);
    return json;
  } catch (err) {
    return {
      detail: error.message,
    };
  }
}

export default {
  name: "login",
  data() {
    return {
      isBusy: false,
      errorMessage: "",
      requireRegister: false,
      model: {
        email: null,
        password: null,
        confirmCode: null,
        name: null,
      },
    };
  },
  mounted() {
    return makeApi()
      .getVersion()
      .then((data) => console.log(`api version: ${JSON.stringify(data)}`));
  },
  methods: {
    goOrganization() {
      this.$router.push("organizations");
    },
    ajacentApi() {
      const apiStatusNotification = {
        onStart: () => (this.isBusy = true),
        onCompletes: () => (this.isBusy = false),
      };

      return makeApi({
        apiStatusNotification,
      });
    },
    confirm() {
      const _data = this.model;

      this.ajacentApi()
        .confirm(_data)
        .then((token) => {
          settings.saveUserToken(token);
          this.goOrganization();
        })
        .catch((err) => {
          console.log(err);
          const errorobj = errorObject(err);

          const { detail, type } = errorobj;
          this.errorMessage = detail;
          this.requireRegister = type === "api:users:password_required";
        });
    },
    login() {
      const _data = this.model;

      this.errorMessage = "";

      return this.ajacentApi()
        .login(_data)
        .catch((err) => {
          console.log(err);
          throw new Error("wrong email or password");
        })
        .then((token) => {
          settings.saveUserToken(token);
          this.goOrganization();
        })
        .catch((err) => {
          this.model.email = null;
          this.model.password = null;

          const errorobj = errorObject(err);
          const { detail } = errorobj;
          this.errorMessage = detail;
        });
    },
  },
};
</script>
<style>
</style>
