function buildUri(apiPath) {
  const BASE_URI = process.env.VUE_APP_API_URI;
  return `${BASE_URI}${apiPath}`;
}

export default function makeApi(_apiOptions) {
  let apiOptions = _apiOptions || {};
  const { jwt, organizationId, apiStatusNotification } = apiOptions;

  function apiWrapper(p) {
    if (apiStatusNotification && apiStatusNotification.onStart) {
      apiStatusNotification.onStart();
    }
    return p
      .catch((err) => {
        if (apiStatusNotification && apiStatusNotification.onStart) {
          apiStatusNotification.onCompletes();
        }

        throw err;
      })
      .then((res) => {
        if (apiStatusNotification && apiStatusNotification.onStart) {
          apiStatusNotification.onCompletes();
        }
        return res;
      });
  }

  function get(uri) {
    return apiWrapper(fetch(uri, {
      method: 'GET',
      headers: buildHeaders(),
    }))
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .catch((err) => {
        return err.text().then(errorMessage => {
          throw new Error(errorMessage);
        });
      });
  }

  function post(uri, data, option) {
    return apiWrapper(fetch(uri, {
      method: 'POST',
      body: data instanceof FormData ? data : JSON.stringify(data),
      headers: buildHeaders(option),
    }))
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        if (option && option.emptyReturn) {
          return;
        }

        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err.text().then(errorMessage => {
          throw new Error(errorMessage);
        });
      });
  }

  function put(uri, data, option) {
    return apiWrapper(fetch(uri, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: buildHeaders(option),
    }))
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        if (option && option.emptyReturn) {
          return;
        }

        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err.text().then(errorMessage => {
          throw new Error(errorMessage);
        });
      });
  }

  function del(uri) {
    return apiWrapper(fetch(uri, {
      method: 'DELETE',
      headers: buildHeaders(),
    }))
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .catch((err) => {
        return err.text().then(errorMessage => {
          throw new Error(errorMessage);
        });
      });
  }

  function buildHeaders(options) {
    if (!options) {
      return {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${jwt}`
      };
    }

    if (options.isFormPost) {
      return {
        'Authorization': `Bearer ${jwt}`
      };
    }

    return {
      'Content-type': 'application/json; charset=UTF-8',
    };
  }

  function login(data) {
    const uri = buildUri('/v1/users/token');

    return post(uri, data, {
      authorization: false,
    });
  }

  function register(data) {
    const uri = buildUri('/v1/users');

    return post(uri, data, {
      authorization: false,
    });
  }

  function confirm(data) {
    const uri = buildUri('/v1/users/confirm');

    return post(uri, data, {
      authorization: false,
    });
  }

  function organizationLogin() {
    const uri = buildUri(`/v1/organizations/${organizationId}/token`);

    return post(uri, {});
  }

  function getAllOrganizations() {
    const uri = buildUri('/v1/organizations');

    return get(uri);
  }

  function getOrganization() {
    const uri = buildUri(`/v1/organizations/${organizationId}`);

    return get(uri);
  }

  function findConnectors() {
    const uri = buildUri(`/v1/connectors`);

    return get(uri);
  }

  function addConnector(connector) {
    const uri = buildUri(`/v1/connectors`);

    const { type, refId, accessToken, name } = connector;

    const data = {
      type,
      refId,
      accessToken,
      name,
    };

    return post(uri, data);
  }

  function deleteConnector(connectorId) {
    const uri = buildUri(`/v1/connectors/${connectorId}`);

    return del(uri).then((data) => {
      const { connectors } = data;

      return connectors;
    });
  }

  function findMembers() {
    const uri = buildUri(`/v1/organizations/${organizationId}`);

    return get(uri).then((organization) => ({ data: organization.members }));
  }

  function removeMemberById(memberId) {
    const uri = buildUri(`/v1/organizations/${organizationId}/members/${memberId}`);

    return del(uri).then((organization) => ({ data: organization.members }));
  }

  function findPosts() {
    const uri = buildUri(`/v1/posts`);

    return get(uri);
  }

  function addPost(message) {
    const uri = buildUri(`/v1/posts`);

    const data = {
      message,
      status: 'drafts',
    };

    return post(uri, data);
  }

  function deletePost(postId) {
    const uri = buildUri(`/v1/posts/${postId}`);

    return del(uri);
  }

  function publishPost(postId) {
    const uri = buildUri(`/v1/posts/${postId}/publishstatus`);
    const data = {
      postStatus: 'publish',
    };
    return put(uri, data);
  }

  function updatePostConnectors(postId, connectorIds) {
    const uri = buildUri(`/v1/posts/${postId}/connectors`);

    const data = {
      connectorIds,
    };

    return put(uri, data);
  }

  function addPostContent(postId, file) {
    const uri = buildUri(`/v1/posts/${postId}/feeds/contents`);

    const formData = new FormData();

    formData.append('files', file);

    return post(uri, formData, {
      isFormPost: true,
    });
  }

  function inviteMember(email, scopes) {
    const uri = buildUri(`/v1/organizations/${organizationId}/members`);

    const data = {
      data: [
        {
          email, scopes,
        }
      ]
    };
    return post(uri, data);
  }

  function findReports() {
    const uri = buildUri(`/v1/reports/me/usages/organizations/${organizationId}`);

    return get(uri);
  }

  function getVersion() {
    const uri = buildUri('/v1/system/version');

    return get(uri);
  }

  function getSentimentReport(postId) {
    const uri = buildUri(`/v1/sentiment/report?postId=${postId}`);

    return get(uri);
  }

  function getSentimentDetail(postId) {
    const uri = buildUri(`/v1/sentiment?postId=${postId}`);

    return get(uri);
  }

  function getProducts() {
    const uri = buildUri('/v1/products');

    return get(uri);
  }

  function getPurchases() {
    const uri = buildUri('/v1/purchases');

    return get(uri);
  }

  function buildStripeUri(uri) {
    const { protocol, hostname, port } = window.location;

    const url = port ? `${protocol}//${hostname}:${port}/${uri}` :
      `${protocol}//${hostname}/${uri}`;

    return url;
  }

  function changeSubscription(productId, customerId, organizationId, oldPurchaseId) {
    if (oldPurchaseId) {
      const uri = buildUri(`/v1/purchases/${oldPurchaseId}/subscription`);

      const data = {
        newProductId: productId,
        successUrl: buildStripeUri('/stripe_cb.html'),
        cancelUrl: buildStripeUri('/stripe_cb.html#error'),
      }

      return put(uri, data);
    } else {
      const uri = buildUri('/v1/purchases');
      const data = {
        productId,
        customerId,
        organizationId,
        quantity: 1,
        paymentProvider: 'stripe',
        productType: 'subscription',
        successUrl: buildStripeUri('/stripe_cb.html'),
        cancelUrl: buildStripeUri('/stripe_cb.html#error'),
      };

      return post(uri, data);
    }
  }

  function getSupports() {

    const uri = buildUri('/v1/me/supports');

    return get(uri);
  }

  function createSupport(serviceType, subject, description) {
    const uri = buildUri('/v1/me/supports');

    const data = {
      serviceType,
      subject,
      description,
    };

    return post(uri, data);
  }

  return {
    confirm,
    login,
    register,
    getAllOrganizations,
    getOrganization,
    findReports,
    addConnector,
    findConnectors,
    findMembers,
    removeMemberById,
    findPosts,
    addPost,
    deleteConnector,
    deletePost,
    publishPost,
    updatePostConnectors,
    addPostContent,
    organizationLogin,
    inviteMember,
    getVersion,
    getSentimentReport,
    getSentimentDetail,
    getProducts,
    getPurchases,
    changeSubscription,
    getSupports,
    createSupport,
  };
}
