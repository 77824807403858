<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            type="primary"
            size="sm"
            @click="$bvModal.show('modal-scoped')"
            >Add New Posts</base-button
          >
        </div>
      </div>
    </div>

    <!-- New Post Popup -->
    <b-modal
      id="modal-scoped"
      @ok="addPost"
      @cancel="cancelPost"
      title="Add New Post"
    >
      <b-tabs content-class="mt-3">
        <b-tab title="Message" active>
          <b-form-input
            v-model="newPost.message"
            placeholder="Enter Your Post Message"
          ></b-form-input>
        </b-tab>
        <!-- TAB - Connectors -->
        <b-tab title="Connectors">
          <b-form-checkbox-group
            v-model="newPost.connectorIds"
            :options="connectorData"
            class="mb-3"
            value-field="connectorId"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-checkbox-group>
        </b-tab>
        <!-- TAB - Feeds -->
        <b-tab title="Feeds">
          <b-form-file
            v-model="newPost.contents"
            :state="Boolean(newPost.contents)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="primary" size="sm" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" size="sm" @click="ok()"> Add </b-button>
      </template>
    </b-modal>
    <!-- End of New Post Popup -->

    <!-- Sentiment Detail Popup -->
    <b-modal
      id="modal-sentiment-detail"
      size="lg"
      :title="`Details of Sentiment of Comments - ${sentiment.post.message}`"
      ok-only
    >
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="sentiment.detail">
          <template slot="columns">
            <th>Message</th>
            <th>Sentiment</th>
            <th>Created At</th>
          </template>

          <template slot-scope="{ row }">
            <th scope="row">
              {{ row.message }}
            </th>
            <td>
              {{ row.sentiment }}
            </td>
            <td>
              {{ row.createdTime }}
            </td>
          </template>
        </base-table>
      </div>
    </b-modal>
    <!-- End of Sentiment Detail Popup -->

    <!-- Sentiment Popup -->
    <b-modal
      id="modal-sentiment"
      size="lg"
      :title="`Sentiment of Comments - ${sentiment.post.message}`"
      ok-only
    >
      <div>
        <div>
          <b-card-group deck>
            <b-card
              border-variant="danger"
              header="Negative"
              header-border-variant="danger"
              header-text-variant="danger"
              align="center"
            >
              <i class="far fa-frown"></i>
              <b-card-text>{{ sentiment.report.negative }}</b-card-text>
            </b-card>

            <b-card
              border-variant="dark"
              header-border-variant="dark"
              header="Neutral"
              align="center"
            >
              <i class="far fa-meh"></i>
              <b-card-text>{{ sentiment.report.neutral }}</b-card-text>
            </b-card>

            <b-card
              border-variant="success"
              header-border-variant="success"
              header="Positive"
              align="center"
            >
              <i class="far fa-smile-beam"></i>
              <b-card-text>{{ sentiment.report.positive }}</b-card-text>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </b-modal>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>Message</th>
          <th>Status</th>
          <th>Feeds</th>
          <th>Connectors</th>
          <th>Created At</th>
          <th></th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.message }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.status }}
          </td>

          <td class="budget">
            {{ row.feedsNo }}
          </td>

          <td class="budget">
            {{ row.connectorsNo }}
          </td>

          <td class="budget">
            {{ row.createdAt }}
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="publishPost(row.id)"
                  v-show="row.status === 'drafts'"
                  >Publish</a
                >
                <a class="dropdown-item" href="#" @click="deletePost(row.id)"
                  >Delete</a
                >

                <a
                  class="dropdown-item"
                  href="#"
                  @click="viewSentiment(row)"
                  v-show="row.status === 'published'"
                  >View Sentiment Report</a
                >

                <a
                  class="dropdown-item"
                  href="#"
                  @click="viewSentimentDetail(row)"
                  v-show="row.status === 'published'"
                  >View Sentiment Detail</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>

      <!-- was outside -->
      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <base-pagination :total="30"></base-pagination>
      </div>
      <!-- end of was outside -->

      <b-overlay
        :show="isBusy"
        spinner-variant="dark"
        spinner-type="grow"
        spinner-small
        rounded="sm"
        no-wrap
      >
      </b-overlay>
    </div>
  </div>
</template>
<script>
import makeAjacentApi from "../../api/ajacent";
import settings from "../../store/settings";

function prettyTime(t) {
  return new Date(t).toLocaleString();
}

function mapPosts(posts) {
  return posts.map((item) => ({
    id: item.id,
    message: item.message,
    status: item.status,
    feedsNo: (item.feeds && item.feeds.length) || 0,
    connectorsNo: (item.connectors && item.connectors.length) || 0,
    publishAt: item.publishAt,
    createdAt: prettyTime(item.createdAt),
  }));
}

function mapConnectors(connectors) {
  return connectors.map((item) => ({
    name: item.name,
    connectorId: item.connectorId,
    refId: item.refId,
    type: "facebook",
  }));
}

function mapSentimentReport(report) {
  const { sentiments } = report;

  if (!sentiments) {
    return {
      positive: 0,
      negative: 0,
      neutral: 0,
    };
  }
  const { positive = 0, negative = 0, neutral = 0 } = sentiments;

  return {
    positive,
    negative,
    neutral,
  };
}

function mapSentimentDetail(detail) {
  return detail.map((item) => {
    const { commentId, message, createdTime, sentimentInfo } = item;

    const { sentiment } = sentimentInfo;

    return {
      commentId,
      message,
      createdTime: prettyTime(createdTime),
      sentiment,
    };
  });
}

export default {
  name: "posts-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      isBusy: false,
      newPost: {
        message: "",
        connectorIds: [],
        contents: null,
      },
      tableData: [],
      connectorData: [],
      sentiment: {
        post: {},
        report: {},
        detail: [],
      },
    };
  },
  mounted() {
    return this.refreshPosts().then(() => {
      this.refreshConnectors();
    });
  },
  methods: {
    ajacentApi() {
      const jwt = settings.getJwt();
      const organizationId = settings.getOrganizationId();

      const apiStatusNotification = {
        onStart: () => (this.isBusy = true),
        onCompletes: () => (this.isBusy = false),
      };

      const ajacent = makeAjacentApi({
        jwt,
        organizationId,
        apiStatusNotification,
      });

      return ajacent;
    },
    addPost() {
      const ajacent = this.ajacentApi();

      const { message, connectorIds, contents } = this.newPost;

      return ajacent
        .addPost(message)
        .then((payload) => {
          const { id: postId } = payload;

          return ajacent
            .updatePostConnectors(postId, connectorIds)
            .then(() => ajacent.addPostContent(postId, contents));
        })
        .then(() => this.refreshPosts());
    },
    cancelPost() {
      this.newPost = {
        message: "",
        connectorIds: [],
        contents: null,
      };
    },
    deletePost(postId) {
      const ajacent = this.ajacentApi();

      return ajacent.deletePost(postId).then(() => {
        this.refreshPosts();
      });
    },
    publishPost(postId) {
      const ajacent = this.ajacentApi();

      return ajacent.publishPost(postId).then(() => {
        this.refreshPosts();
      });
    },
    viewSentiment(post) {
      const ajacent = this.ajacentApi();

      return ajacent.getSentimentReport(post.id).then((res) => {
        this.sentiment.post = post;
        this.sentiment.report = mapSentimentReport(res.data);

        this.$bvModal.show("modal-sentiment");
      });
    },
    viewSentimentDetail(post) {
      const ajacent = this.ajacentApi();

      return ajacent.getSentimentDetail(post.id).then((res) => {
        this.sentiment.post = post;
        this.sentiment.detail = mapSentimentDetail(res.data);

        this.$bvModal.show("modal-sentiment-detail");
      });
    },
    refreshPosts() {
      const ajacent = this.ajacentApi();

      return ajacent.findPosts().then((payload) => {
        const { data } = payload;
        this.tableData = mapPosts(data);
      });
    },
    refreshConnectors() {
      const ajacent = this.ajacentApi();

      return ajacent.findConnectors().then((payload) => {
        const { data } = payload;
        this.connectorData = mapConnectors(data);
      });
    },
  },
};
</script>
<style>
</style>
