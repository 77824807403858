<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            type="primary"
            size="sm"
            @click="$bvModal.show('modal-scoped')"
            >Invite New Member</base-button
          >
        </div>
      </div>
    </div>

    <b-modal
      id="modal-scoped"
      @ok="inviteMember"
      @cancel="cancelInviteMember"
      title="Invite New Member"
    >
      <b-tabs content-class="mt-3">
        <b-tab title="Email" active>
          <b-form-input
            v-model="newMember.email"
            placeholder="Enter New Member's Email"
          ></b-form-input>
        </b-tab>
        <!-- TAB - Connectors -->
        <b-tab title="Scopes">
          <b-form-checkbox-group
            v-model="newMember.scopes"
            :options="defaultMemberScopes"
            class="mb-3"
            value-field="connectorId"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-checkbox-group>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="primary" size="sm" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" size="sm" @click="ok()"> Invite </b-button>
      </template>
    </b-modal>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>Email</th>
          <th>Confirmed</th>
          <th>Permissions</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th></th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.email }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.isConfirmed }}
          </td>

          <td class="budget">
            {{ row.permissions }}
          </td>

          <td class="budget">
            {{ row.createdAt }}
          </td>

          <td class="budget">
            {{ row.updatedAt }}
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  v-show="!row.isConfirmed"
                  @click="reinviteMemeber(row)"
                  >Re-invite</a
                >
                <a class="dropdown-item" href="#" @click="removeMember(row.id)"
                  >Delete</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
      <!-- was outisde -->
      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <base-pagination :total="30"></base-pagination>
      </div>
      <!-- end of was outside -->
    </div>
  </div>
</template>
<script>
import makeAjacentApi from "../../api/ajacent";
import settings from "../../store/settings";

// function mapPosts(posts) {
//   return posts.map((item) => ({
//     id: item.id,
//     message: item.message,
//     status: item.status,
//     feedsNo: (item.feeds && item.feeds.length) || 0,
//     connectorsNo: (item.connectors && item.connectors.length) || 0,
//     publishAt: item.publishAt,
//   }));
// }

function mapMembers(members) {
  return members.map((item) => ({
    id: item.userId,
    email: item.email,
    scopes: item.scopes,
    permissions: "regular",
    isConfirmed: item.isConfirmed,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }));
}

const DEFAULT_MEMBER_SCOPES = [
  "me:organization:read",
  "me:connector:read",
  "me:post:read",
  "me:post:write",
  "me:post:delete",
  "me:feed:read",
  "me:feed:write",
  "me:feed:delete",
];

export default {
  name: "members-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      isBusy: false,
      newMember: {
        email: "",
        scopes: DEFAULT_MEMBER_SCOPES,
      },
      tableData: [],
      defaultMemberScopes: DEFAULT_MEMBER_SCOPES,
    };
  },
  mounted() {
    return this.refreshMembers();
  },
  methods: {
    ajacentApi() {
      const jwt = settings.getJwt();
      const organizationId = settings.getOrganizationId();

      const ajacent = makeAjacentApi({ jwt, organizationId });

      return ajacent;
    },
    inviteMember() {
      const ajacent = this.ajacentApi();
      const { email, scopes } = this.newMember;

      return ajacent.inviteMember(email, scopes).then(() => {
        return this.refreshMembers();
      });
    },
    reinviteMemeber(member) {
      const ajacent = this.ajacentApi();
      const { email, scopes } = member;

      return ajacent.inviteMember(email, scopes).then(() => {
        return this.refreshMembers();
      });
    },
    cancelInviteMember() {
      this.newMember = {
        email: "",
        scopes: DEFAULT_MEMBER_SCOPES,
      };
    },
    removeMember(postId) {
      const ajacent = this.ajacentApi();

      return ajacent.removeMemberById(postId).then(() => {
        this.refreshMembers();
      });
    },
    refreshMembers() {
      const ajacent = this.ajacentApi();

      return ajacent.findMembers().then((payload) => {
        const { data } = payload;
        this.tableData = mapMembers(data);
      });
    },
  },
};
</script>
<style>
</style>
