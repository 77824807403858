<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Organizations</h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="tableData">
        <template slot="columns">
          <th>Name</th>
          <th>Role</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            {{ row.title }}
          </th>
          <td>
            {{ row.role }}
          </td>
          <td>
            <a
              href="#!"
              class="btn btn-sm btn-primary"
              @click="organizationLogin(row.id)"
              >Select</a
            >
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import makeAjacentApi from "../../api/ajacent";
import settings from "../../store/settings";

function mapOrganizations(data) {
  return data.map((organization) => {
    const { id, title, userType } = organization;

    return {
      id,
      title,
      role: userType,
    };
  });
}

export default {
  name: "organizations-table",
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    return this.refresh();
  },
  methods: {
    ajacentApi() {
      const jwt = settings.getUserJwt();

      const ajacent = makeAjacentApi({ jwt });

      return ajacent;
    },
    refresh() {
      const ajacent = this.ajacentApi();

      return ajacent.getAllOrganizations().then((payload) => {
        const { data } = payload;
        this.tableData = mapOrganizations(data);
      });
    },
    organizationLogin(organizationId) {
      const jwt = settings.getUserJwt();

      return makeAjacentApi({ jwt, organizationId })
        .organizationLogin()
        .then((token) => {
          settings.saveToken(token);
          settings.saveOrganizationId(organizationId);
        }).then(() => this.go2Dashboard())
    },
    go2Dashboard() {
      this.$router.push("dashboard");
    },
  },
};
</script>
<style>
</style>
