
<template>
  <div class="row justify-content-center">
    <organizations-table></organizations-table>
  </div>
</template>
<script>
import OrganizationsTable from "./Tables/OrganizationsTable";

export default {
  name: "organizations",
  components: {
    OrganizationsTable,
  },
};
</script>
<style></style>