<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <h3>{{ getTitle() }}</h3>
    </base-header>

    <div class="container-fluid mt--7">
      <div>
        <b-dropdown id="dropdown-1" class="m-md-2">
          <b-dropdown-item @click="showOption('current-subscription')">Current Subscription</b-dropdown-item>
          <b-dropdown-item @click="showOption('change-subscription')">Change Subscription</b-dropdown-item>
          <b-dropdown-item @click="showOption('view-supports')">View Past Supports</b-dropdown-item>
          <b-dropdown-item @click="showOption('create-support')">Create New Support</b-dropdown-item>
        </b-dropdown>
      </div>

      <div v-show="displayOption === 'current-subscription'">
      <b-card-group deck>
        <b-card :title="purchase.productData.productName" v-for="purchase in purchases" v-bind:key="purchase.id">
          <b-card-text>
            {{ purchase.productData.currency.toUpperCase() }}:
            ${{ purchase.productData.priceInCents / 100.0 }}
            {{ purchase.productData.productType }}
          </b-card-text>
        </b-card>
      </b-card-group>
      </div>

      <div v-show="displayOption === 'change-subscription'">
      <b-card-group deck>
        <b-card :title="product.productName" v-for="product in products" v-bind:key="product.id">
          <b-card-text>
            {{ product.currency.toUpperCase() }}:
            ${{ product.priceInCents / 100.0 }}
            <b-badge variant="primary">{{ product.productType }}</b-badge>
          </b-card-text>
          <b-button variant="primary" @click="selectProduct(product.id)">Select</b-button>
        </b-card>
      </b-card-group>
      </div>

      <div v-show="displayOption === 'view-supports'">
        <!-- table -->
        <div class="card">
          <base-table thead-classes="thead-light" :data="supports">
            <template slot="columns">
              <th>Subject</th>
              <th>Service Type</th>
              <th>Status</th>
              <th>Description</th>
            </template>

            <template slot-scope="{ row }">
              <td scope="row">
                {{ row.subject }}
              </td>
              <td>
                {{ row.serviceType }}
              </td>
              <td>
                {{ row.status }}
              </td>
              <td>
                {{ row.description }}
              </td>
            </template>
          </base-table>
        </div>
      </div>

      <div v-show="displayOption === 'create-support'">
         <b-card bg-variant="light">
    <b-form-group
      label-cols-lg="2"
      label="Create Support"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Service Type:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          class="pt-2"
          v-model="newSupportForm.serviceType"
          :options="['general', 'refund']"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
        </b-form-group>
      </b-form-group>

      <b-form-group
        label="Subject:"
        label-for="nested-subject"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-subject" v-model="newSupportForm.subject"></b-form-input>
      </b-form-group>

      <b-form-group
        label="Description:"
        label-for="nested-description"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-description" v-model="newSupportForm.description"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" @click="createSupport">Create</b-button>

      </b-card>

      </div>

      <b-modal id="error-modal" title="Change Subscription Error">
      <p class="my-4" >{{ changeSubscriptionResult.errorMessage }}</p>
      </b-modal>

    </div>
  </div>
</template>
<script>

import makeAjacentApi from "../api/ajacent";
import settings from "../store/settings";

function connectStripe(url) {
  const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=800,left=100,top=100`;

  window.open(url, "Payment", params);
}

export default {
  name: "account",
  components: {
  },
  data() {
    return {
      displayOption: 'current-subscription',
      products: [],
      purchases: [],
      supports: [],
      newSupportForm: {
      },
      paymentDetail: {
      },
      changeSubscriptionResult: {

      }
    };
  },
  mounted() {
    window.addEventListener("message", this.onMessage, false);

    return this.refreshAll().then(() => {
      if (this.purchases.length > 0) {
        const purchase = this.purchases[0];
        const { customerId, organizationId } = purchase;
        this.paymentDetail = {
          customerId,
          organizationId,
        };

        console.log(customerId);
      }
    });
  },
  methods: {
    ajacentApi() {
      const jwt = settings.getJwt();
      const organizationId = settings.getOrganizationId();

      const ajacent = makeAjacentApi({ jwt, organizationId });

      return ajacent;
    },
    onMessage(evt) {
      console.log(`${evt.origin} -- ${evt.data}`);
    },
    refreshAll() {
      return this.refreshPurchases().then(() => this.refreshProducts());
    },
    refreshProducts() {
      const ajacent = this.ajacentApi();

      return ajacent.getProducts().then((products) => {
        this.products = products.data
          .filter((p) => (p.productType === 'subscription')
            && this.purchases.find((purchase) => purchase.productId === p.id) === undefined );
      });
    },
    refreshSupports() {
      const ajacent = this.ajacentApi();

      return ajacent.getSupports().then((result) => {
        this.supports = result.data;
      });
    },
    refreshPurchases() {
      const ajacent = this.ajacentApi();

      return ajacent.getPurchases().then((purchases) => {
        console.log(purchases);
        this.purchases = purchases.data.filter((p) => p.checkoutStatus === 'succeeded');
      });
    },
    showOption(option) {
      this.displayOption = option;

      if (this.displayOption === 'view-supports') {
        return this.refreshSupports();
      } else if (this.displayOption === 'create-support') {
        this.newSupportForm = {
          subject: '',
          serviceType: 'general',
          description: '',
        };
      }
    },
    createSupport() {
      const { serviceType, subject, description } = this.newSupportForm;
      const ajacent = this.ajacentApi();

      return ajacent.createSupport(serviceType, subject, description).then(() => {
        return this.showOption('view-supports');
      });
    },
    getTitle() {
      switch(this.displayOption) {
        case 'change-subscription':
          return 'Change Subscription';

        case 'current-subscription':
          return 'Current Subscription';

        case 'view-supports':
          return 'Past Supports';
        
        case 'create-support':
          return 'New Support';
      }
    },
    showError(errorMessage) {
      this.changeSubscriptionResult = {
          errorMessage: errorMessage,
        };

      this.$bvModal.show('error-modal');
    },
    selectProduct(productId) {
      const ajacent = this.ajacentApi();
      const { customerId, organizationId } = this.paymentDetail;

      const oldPurchaseId = this.purchases.length && this.purchases[0].id;
      console.log('*** oldPurchaseId', oldPurchaseId);

      return ajacent.changeSubscription(productId, customerId, organizationId, oldPurchaseId)
        .then((result) => {
          console.log('******', result);
          try {
            const { url } = result;

            connectStripe(url);
          } catch(e) {
            this.showError(e.message);
          }
        }).catch((err) => {
          try {
            const json = JSON.parse(err.message);
            this.changeSubscriptionResult = {
              errorMessage: json.detail,
            };
          } catch(e) {
            this.changeSubscriptionResult = {
              errorMessage: err.message,
            };
          }
          
          this.$bvModal.show('error-modal');
          // this.$bvToast.toast(err.message, {
          //  title: 'Change Subscription Error',
          //  autoHideDelay: 10000,
          //  appendToast: true,
          // });

        }) ;
    },
  },
};
</script>
<style></style>
